<template>
  <div id="home">
    <keep-alive>
      <router-view></router-view>
    </keep-alive>
    <!-- 1D4B89 -->
    <van-tabbar
      v-model="active"
      active-color="#1D4B89"
      inactive-color="#666"
      @change="onChange"
      :safe-area-inset-bottom="false"
      v-show="active !== 2"
      z-index="9999"
    >
      <!-- :placeholder="true" -->
      <van-tabbar-item
        :badge="
          item.pathName === 'shoppingCart'
            ? quantity > 99
              ? '99+'
              : quantity
            : ''
        "
        v-for="item in nav"
        :key="item.pathName"
      >
        <span>{{ item.name }}</span>
        <template #icon="props">
          <svg-icon
            :icon-class="props.active ? item.onIconName : item.iconName"
          />
        </template>
      </van-tabbar-item>
    </van-tabbar>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { WechatSharing } from "@/utils/sdk";
export default {
  name: "home",
  computed: {
    ...mapState("user", ["quantity"]),
  },
  data() {
    return {
      active: 0,
      nav: [
        {
          name: "首页",
          pathName: "homePage",
          iconName: "tabble_home_normal",
          onIconName: "tabble_home_normal1",
        },
        {
          name: "分类",
          pathName: "classification",
          iconName: "tabble_class_normal",
          onIconName: "tabble_class_normal1",
        },
        {
          name: "视频",
          pathName: "video",
          iconName: "video",
          onIconName: "video1",
        },
        {
          name: "购物车",
          pathName: "shoppingCart",
          iconName: "tabble_shopping_normal_icon",
          onIconName: "tabble_shopping_normal_icon1",
        },
        {
          name: "我的",
          pathName: "mine",
          iconName: "tabble_me_normal_icon",
          onIconName: "tabble_me_normal_icon1",
        },
      ],
    };
  },
  watch: {
    $route: {
      handler(val) {
        if (this.$route.query.id) {
          sessionStorage.setItem("userUuid", this.$route.query.id);
        }
        switch (val.name) {
          case "home-page":
            this.active = 0;
            if (!this.$route.query.id && sessionStorage.getItem("userUuid")) {
              this.$router.replace({
                path: "homePage",
                query: {
                  id: sessionStorage.getItem("userUuid"),
                },
              });
            }
            break;
          case "classification":
            this.active = 1;
            break;
          case "video":
            this.active = 2;
            break;
          case "shopping-cart":
            this.active = 3;
            break;
          case "mine":
            this.active = 4;
            break;
          default:
            break;
        }
      },
      immediate: true,
    },
  },
  created() {
    // if (window.Android) {
    //   // alert("app内打开");
    //   let t = {
    //     type: 1,
    //     data: {
    //       token: "asjhdj23jkhdjkhsajdkas",
    //     },
    //   };
    //   Android.callAndroid(JSON.stringify(t));
    // }

    document.body.style.overflow = "scroll";
    // document.documentElement.scrollTop = 0;
    // console.log(location.href.split("#")[0]);
    // console.log(`${window.location.origin}/`);
    WechatSharing();
  },
  activated() {
    WechatSharing();
  },

  methods: {
    onChange(i) {
      if (sessionStorage.getItem("userUuid")) {
        var id = sessionStorage.getItem("userUuid");
      }
      let nav = this.nav.find((e, idx) => {
        return idx === i;
      });
      this.$router.replace({
        path: nav.pathName,
        query: {
          id: id ? id : undefined,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
#home {
  background-color: #f5f5f5;
  .van-tabbar {
    max-width: 500px;
    margin: 0 auto;
    right: 0;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
  }
  ::v-deep .van-tabbar-item__icon {
    font-size: 21px;
  }
}
</style>
